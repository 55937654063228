import {VISUALISATION_TITLE} from "../data/visualisation";
import {generateComponentTitleFromDate} from "./date";
import { DataFetchWithIdNames } from "./query";


interface VisualisationData {
    date: string;
    [key: string]: number | string;
}

export const transformGroupByResponseToVisualisationData = (response: DataFetchWithIdNames| undefined): VisualisationData[] => {
    if (!response) return [];
    const result: VisualisationData[] = [];

    const buckets = response.userAnalyticsData.aggregations.events_over_time.buckets;

    for (const bucket of buckets) {
        const date = bucket.key_as_string;
        const aggregation: VisualisationData = {
            date: new Date(date).toDateString()
        };
        for (const groupByBucket of bucket.group_by.buckets) {
            aggregation[`${response.idNames[groupByBucket.key].name}`] = groupByBucket.doc_count;
        }
        result.push(aggregation)
    }
    return result;
}

export const generateVisualisationTitleFromDate = (start: Date | null, end: Date | null, interval: string): string => {
    return generateComponentTitleFromDate(start, end, interval, VISUALISATION_TITLE);
}
