import { GridColDef, GridValueFormatterParams } from "@mui/x-data-grid"

import {
    GROUP_TABLE_TITLE,
    defaultDateColumn,
    defaultCountColumn,
    defaultGroupByColumn
} from "../data/table";
import {generateComponentTitleFromDate} from "./date";
import { DataFetchWithIdNames } from "./query";


interface TableData {
    id: string;
    date: Date;
    groupBy: string;
    count: number;
}

export const transformGroupByResponseToTableData = (response: DataFetchWithIdNames | undefined): TableData[] => {
    if (!response) return [];
    const result: TableData[] = [];

    const buckets = response.userAnalyticsData.aggregations.events_over_time.buckets;

    for (const bucket of buckets) {
        const date = bucket.key_as_string;
        for (const groupByBucket of bucket.group_by.buckets) {
            result.push({
                id: `${date}-${groupByBucket.key}`,
                date: new Date(date),
                groupBy: `${response.idNames[groupByBucket.key].name}`,
                count: groupByBucket.doc_count,
            });
        }
    }
    return result;
}

export const generateTableTitleFromDate = (start: Date | null, end: Date | null, interval: string): string => {
   return generateComponentTitleFromDate(start, end, interval, GROUP_TABLE_TITLE);
}

const dateValueToDayFormat = (params: GridValueFormatterParams<Date>) => {
    return params.value.toLocaleDateString();
}

const dateValueToMonthFormat = (params: GridValueFormatterParams<Date>) => {
    return `${params.value.getMonth()+1}/${params.value.getFullYear()}`;
}

const dateValueToWeekFormat = (params: GridValueFormatterParams<Date>) => {
    const startOfWeek = new Date(params.value);
    startOfWeek.setDate(startOfWeek.getDate() - startOfWeek.getDay());
    const endOfWeek = new Date(startOfWeek);
    endOfWeek.setDate(endOfWeek.getDate() + 6);
    return `${startOfWeek.getMonth()+1}/${startOfWeek.getDate()}/${startOfWeek.getFullYear()} - ${endOfWeek.getMonth()+1}/${endOfWeek.getDate()}/${endOfWeek.getFullYear()}`;
}

const dateValueToYearFormat = (params: GridValueFormatterParams<Date>) => {
    return params.value.getFullYear();
}


export const generateGroupByColumnDefinitionsFromInterval = (interval: string, groupByHeader: string): GridColDef[] => {
    let dateColumn;
    switch (interval) {
        case 'day':
            dateColumn = {
                headerName: 'Day',
                valueFormatter: dateValueToDayFormat
            }
            break;
        case 'week':
            dateColumn = {
                headerName: 'Week',
                flex: 2,
                valueFormatter: dateValueToWeekFormat
            }
            break;
        case 'month':
            dateColumn = {
                headerName: 'Month',
                valueFormatter:  dateValueToMonthFormat
            }
            break;
        case 'year':
            dateColumn = {
                headerName: 'Year',
                valueFormatter: dateValueToYearFormat
            }
            break;
        default:
            throw new Error("Unsupported interval");
    }

    return [
        {...defaultDateColumn, ...dateColumn},
        {...defaultGroupByColumn, headerName: groupByHeader},
        defaultCountColumn
    ]
}

