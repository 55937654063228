import React from 'react';
import { BarChart, Bar, LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer } from 'recharts';
import useStore from '../context/store';

interface VisualisationProps {
    data: any[];
}

const getDataKeys = (data: any[]) => {

    const dataKeys = new Set();
    data.forEach((entry: any) => {
        Object.keys(entry).forEach((key) => {
            if (key !== 'date') {
                dataKeys.add(key);
            }
        })
    })

    return Array.from(dataKeys)
}

/**
 * Creates a 6-digit colour code for each name 
 * alliowing to have different colour for each 
 * user/organisation.
 * 
 */
function getColorFromKey(name: string) {
    let hash = 0;
    for (let i = 0; i < name.length; i++) {
        hash = name.charCodeAt(i) + ((hash << 5) - hash);
    }
    let color = '#';
    for (let i = 0; i < 3; i++) {
        const value = (hash >> (i * 8)) & 0xFF;
        color += ('00' + value.toString(16)).substr(-2);
    }
    return color;
}

const getChart = (chartType: string | null, data: any[]) => {
    switch (chartType) {
        case 'bar':
            return (
                <BarChart
                    width={500}
                    height={300}
                    data={data}
                    margin={{
                        top: 5,
                        right: 30,
                        left: 20,
                        bottom: 5,
                    }}
                >
                    <CartesianGrid strokeDasharray="3 3" />
                    <XAxis dataKey="date" />
                    <YAxis />
                    <Tooltip />
                    {getDataKeys(data).map((key) => {
                        const keyString = key as string;
                        return <Bar key={keyString} dataKey={keyString} fill={getColorFromKey(keyString)} />
                    })}
                </BarChart>
            );
        case 'line':
        default:
            return (
                <LineChart
                    width={500}
                    height={300}
                    data={data}
                    margin={{
                        top: 5,
                        right: 30,
                        left: 20,
                        bottom: 5,
                    }}
                >
                    <CartesianGrid strokeDasharray="3 3" />
                    <XAxis dataKey="date" />
                    <YAxis />
                    <Tooltip />
                    {getDataKeys(data).map((key) => {
                        const keyString = key as string;
                        return <Line type="monotone" key={keyString} dataKey={keyString} stroke={getColorFromKey(keyString)} />
                    })}
                </LineChart>
            );
    }
}

const Visualisation : React.FC<VisualisationProps> = ( {data})=> {


    const [
        chartType,
    ] = useStore((state) => [
        state.chartType,
    ]);

    return (
        <ResponsiveContainer width="100%" height="100%">
            {getChart(chartType, data)}
        </ResponsiveContainer>
    );
}


export default Visualisation;
