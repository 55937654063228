import React from 'react';
import {
  createBrowserRouter,
  RouterProvider,
} from "react-router-dom";

import Root from './Root';
import BreakdownPage from './pages/BreakdownPage';
import { PAGES } from './data/app';


const router = createBrowserRouter([
  {
    path: '/',
    element: <Root />,
    children: PAGES.map((page) => ({
      path: page.path,
      element: <BreakdownPage page={page} />,
    })),
  },
]);

const Routes = () => <RouterProvider router={router}/>

export default Routes;