import { create } from 'zustand';
import { oneMonthAgoAtMidnight, todayAtMidnight } from '../utils/date';
import { ELASTICSEARCH_FILTER_FIELDS, Filters } from '../data/elasticsearch';


interface Store {
    startDate: Date | null,
    endDate: Date | null,
    setStartDate: (date: Date | null) => void,
    setEndDate: (date: Date | null) => void,
    interval: string,
    setInterval: (interval: string) => void,
    filters: Filters
    setFilters: (filters: Filters) => void
    setFilter: (key: keyof typeof ELASTICSEARCH_FILTER_FIELDS, value: string) => void
    chartType: string | null
    setChartType: (chartType: string | null) => void
}

const defaultFilters = Object.keys(ELASTICSEARCH_FILTER_FIELDS).reduce<Filters>((acc, key) => {
    const field = ELASTICSEARCH_FILTER_FIELDS[key];
    acc[field.key] = field.defaultValue;
    return acc;
}, {} as Filters);

const useStore = create<Store>((set) => ({
    startDate: oneMonthAgoAtMidnight(),
    endDate: todayAtMidnight(),
    setStartDate: (date: Date | null) => set({ startDate: date }),
    setEndDate: (date: Date | null) => set({ endDate: date }),
    interval: 'week',
    setInterval: (interval: string) => set({ interval }),
    filters: defaultFilters,
    setFilters: (filters: Filters) => set({ filters }),
    setFilter: (key: keyof typeof ELASTICSEARCH_FILTER_FIELDS, value: string) => set((state) => {
        const filters = { ...state.filters };
        filters[key] = value;
        return { filters }
    }),
    chartType: 'line',
    setChartType: (chartType: string | null) => set({ chartType }),
}));

export default useStore;
