import esb from "elastic-builder";
import { format } from "date-fns";
import { DATE_FIELD, EVENTS_OVER_TIME, GROUP_BY, QUERY_DATE_FORMAT } from "../data/query";
import { Filters } from "../data/elasticsearch";

export interface DateRangeArg {
    field: string;
    from: number | null;
    to: number | null;
}

export interface IntervalArg {
    field: string;
    interval: string;
}

export interface GenerateGroupByRequestArgs {
    aggregation: string;
    dateRange: DateRangeArg;
    interval: IntervalArg;
    filters?: Filters;
}

const generateDateRange = (dateRange: DateRangeArg): esb.RangeQuery | undefined => {
    const { field, from, to } = dateRange;

    if(!from && !to) return;

    const dateRangeQuery = esb.rangeQuery(field);

    if (from) {
        dateRangeQuery.gte(format(from, QUERY_DATE_FORMAT));
    }

    if (to) {
        dateRangeQuery.lte(format(to, QUERY_DATE_FORMAT));
    }

    return dateRangeQuery;
}


export const generateGroupByRequest = ({
    aggregation,
    filters = {},
    dateRange,
    interval = {
        field: DATE_FIELD,
        interval: 'month'
    },
}: GenerateGroupByRequestArgs): object => {
    let requestBody = esb.requestBodySearch();

    requestBody = requestBody.size(0);

    let filterQueries = [];

    // Handling Date Range
    const dateRangeQuery = generateDateRange(dateRange);

    if (dateRangeQuery) {
        filterQueries.push(dateRangeQuery);
    }

    // Handling Interval and Aggregation
    if(interval.field && interval.interval && aggregation) {
        requestBody = requestBody.agg(
            esb
                .dateHistogramAggregation(EVENTS_OVER_TIME, interval.field, interval.interval)
                .agg(esb.termsAggregation(GROUP_BY, aggregation))
            );
    }

    // Handling Filters
    const filtersWithValues = Object.keys(filters).reduce((acc, key) => {
        const value = filters[key as keyof Filters];
        if (value) {
            acc.push({ field: key, value });
        }
        return acc;
    }, [] as { field: string, value: string }[]);

    if (filtersWithValues.length > 0) {
        filterQueries = [...filterQueries, ...filtersWithValues.map(filter => esb.termQuery(filter.field, filter.value as string))];
    }

    if (filterQueries.length > 0) {
        requestBody = requestBody.query(esb.boolQuery().filter(filterQueries));
    }

    return requestBody.toJSON();
}
