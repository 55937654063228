import React, { ReactNode } from 'react';
import AppHeaderBar from './AppHeaderBar';

import Container from '@mui/material/Container';
import { Box } from '@mui/material';

interface AppBodyProps {
    children?: ReactNode;
  }


const AppBody: React.FC<AppBodyProps> = ({children}) => {
    return (
        <Container maxWidth={'xl'} disableGutters={true}>
            <AppHeaderBar />
            <Box padding={'1rem'}>
                {children}
            </Box>
        </Container>
    );
}

export default AppBody;