
import React, { useMemo } from 'react';

import { Page } from '../types/app';
import BreakdownLayout from '../layouts/BreakdownLayout';
import FilterSections from '../components/FilterSections';

import useStore from '../context/store';
import { generateGroupByRequest } from '../utils/queryBuilder';
import { useQuery } from 'react-query';
import { DataFetchWithIdNames, fetchAnalyticsData } from '../utils/query';
import { DATE_FIELD } from '../data/query';
import TableWrapper from '../components/TableWrapper';
import {
    generateTableTitleFromDate,
    transformGroupByResponseToTableData,
    generateGroupByColumnDefinitionsFromInterval,
} from '../utils/table';
import { GridToolbarColumnsButton, GridToolbarContainer, GridToolbarExport, GridToolbarFilterButton } from '@mui/x-data-grid';
import VisualisationWrapper from "../components/VisualisationWrapper";
import {generateVisualisationTitleFromDate, transformGroupByResponseToVisualisationData} from "../utils/visualisation";

const customBreakdownTableToolbar = () => {
    return (
        <GridToolbarContainer>
            <GridToolbarColumnsButton />
            <GridToolbarExport printOptions={{ disableToolbarButton: true } } />
            <GridToolbarFilterButton />
        </GridToolbarContainer>
    );
}

interface BreakdownPageProps {
    page: Page
}

const BreakdownPage: React.FC<BreakdownPageProps> = ({
    page
}) => {

    const [
        startDate,
        endDate,
        interval,
        filters,
    ] = useStore((state) => [
        state.startDate,
        state.endDate,
        state.interval,
        state.filters
    ]);

    const request = useMemo(() => generateGroupByRequest({
        aggregation: page.keyword,
        dateRange: {
            field: DATE_FIELD,
            from: startDate?.getTime() || null,
            to: endDate?.getTime() || null
        },
        interval: {
            field: DATE_FIELD,
            interval: interval
        },
        filters,
    }), [page.keyword, startDate, endDate, interval, filters ]);


    const { data, isLoading } = useQuery<DataFetchWithIdNames>([
        page.keyword,
        startDate,
        endDate,
        interval,
        filters,
    ], () => fetchAnalyticsData(request, page.keyword));

    return (
        <BreakdownLayout
            title={`Breakdown by ${page.name}`}
            filterLayout={<FilterSections />}
            visualisationComponent={ <VisualisationWrapper
                data = {transformGroupByResponseToVisualisationData(data)}
                visualisationTitle = {generateVisualisationTitleFromDate(startDate, endDate, interval)}
            /> }
            tableComponent={<TableWrapper
                data={transformGroupByResponseToTableData(data)}
                columns={generateGroupByColumnDefinitionsFromInterval(interval, page.columnName)}
                tableTitle={generateTableTitleFromDate(startDate, endDate, interval)}
                tableProps={
                    {
                        loading: isLoading,
                        slots: {
                            toolbar: customBreakdownTableToolbar
                        }
                    }
                }
            />}
        />
    );
}

export default BreakdownPage;
