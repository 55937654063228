import React from 'react';

import Grid from '@mui/material/Unstable_Grid2';
import Typography from '@mui/material/Typography';

import { Section } from '../types/app';



interface FilterLayoutProps {
    sections: Section[]
}

const FilterLayout: React.FC<FilterLayoutProps> = ({
    sections
}) => {
    return (
        <Grid container xs={12} spacing={2}>
            {sections.map(({ title, filterComponents }, sectionIndex) => {
                return (
                    <Grid container key={title} xs={6} spacing={1}>
                        <Grid xs={12} >
                            <Typography variant={'h5'}>{title}</Typography>
                        </Grid>
                            {filterComponents.map((filterComponent, filterIndex) => {
                                return (
                                    <Grid xs={12} md={4} key={`${title}${filterIndex}`}>
                                        {filterComponent}
                                    </Grid>
                                )
                            })}
                    </Grid>
                )

            })}
        </Grid>
    );
}

export default FilterLayout;
