import React, { useEffect } from 'react';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';
import { AuthenticatedTemplate, UnauthenticatedTemplate, useIsAuthenticated } from '@azure/msal-react';
import AppBody from './layouts/AppBody';
import { PAGES } from './data/app';


const Root = () => {

  const navigate = useNavigate();
  const location = useLocation();
  const isAuthenticated = useIsAuthenticated();

  useEffect(() => {
    if (isAuthenticated && PAGES.length > 0 && location.pathname === '/') {
      navigate(PAGES[0].path)
    }
  }, [navigate, location.pathname, isAuthenticated])

  return (
    <AppBody>
      <AuthenticatedTemplate>
        <Outlet />
      </AuthenticatedTemplate>
      <UnauthenticatedTemplate>
        <h1>Unauthenticated - please use the login button to authenticate</h1>
      </UnauthenticatedTemplate>
    </AppBody>
  );
}

export default Root;
