interface ElasticsearchFilterField {
    label: string;
    type: string;
    key: string;
    clearable: boolean;
    defaultValue: string;
    items: { name: string, value: string }[];
}

interface ElasticsearchFilterFields {
    [key: string]: ElasticsearchFilterField;
}

export type Filters = {
    [K in keyof typeof ELASTICSEARCH_FILTER_FIELDS]: string
};

export const ELASTICSEARCH_FILTER_FIELDS: ElasticsearchFilterFields = {
    'provider': {
        label: 'Provider',
        type: 'string',
        key: 'provider',
        clearable: true,
        defaultValue: '',
        items: [
            { name: 'Nearmap', value: 'NEARMAP' },
            { name: 'Maxar', value: 'MAXAR' },
            { name: 'Airbus', value: 'AIRBUS' }
        ],
    },
    'retrievedFromCache': {
        label: 'Cached',
        type: 'boolean',
        key: 'retrievedFromCache',
        clearable: true,
        defaultValue: '',
        items: [
            { name: 'Cached', value: 'true' },
            { name: 'Uncached', value: 'false' },
        ],
    },
    'GEOIntelligenceProduct.keyword': {
        label: 'Product',
        type: 'string',
        key: 'GEOIntelligenceProduct',
        clearable: true,
        defaultValue: '',
        items: [
            { name: 'CHD', value: 'CHD' },
            { name: 'GRID', value: 'GRID' },
            { name: 'NUCD', value: 'NUCD' },
            { name: 'CCT', value: 'CCT' }
        ],
    },
}