import React from 'react';
import { Section } from '../types/app';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import useStore from '../context/store';
import Select from '../components/Select';
import FilterLayout from '../layouts/FilterLayout';
import { ELASTICSEARCH_FILTER_FIELDS } from '../data/elasticsearch';

const FilterSections = () => {

    const [
        startDate,
        endDate,
        interval,
        filters,
        setStartDate,
        setEndDate,
        setInterval,
        setFilter,
    ] = useStore(
        (state) => [
            state.startDate,
            state.endDate,
            state.interval,
            state.filters,
            state.setStartDate,
            state.setEndDate,
            state.setInterval,
            state.setFilter,
        ]
    );

    const filterComponents = Object.keys(ELASTICSEARCH_FILTER_FIELDS).map((key) => {
        const field = ELASTICSEARCH_FILTER_FIELDS[key];
        return (
            <Select
                label={field.label}
                selectProps={{
                    sx: { width: '100%' },
                    value: filters[key],
                    onChange: (event) => setFilter(key, event.target.value as string)
                }}
                items={field.items}
                clearFunction={() => setFilter(key, '')}
            />
        );
    });
    const sections: Section[] = [
        {
            title: 'Date Filters',
            filterComponents: [
                <DateTimePicker
                    label="Start Date"
                    sx={{ width: '100%' }}
                    value={startDate}
                    onChange={(date) => setStartDate(date)}
                />,
                <DateTimePicker
                    label="End Date"
                    sx={{ width: '100%' }}
                    value={endDate}
                    onChange={(date) => setEndDate(date)}
                />,
                <Select
                    label='Interval'
                    items={[
                        { name: 'Day', value: 'day' },
                        { name: 'Week', value: 'week' },
                        { name: 'Month', value: 'month' },
                        { name: 'Year', value: 'year' },
                    ]}
                    selectProps={{
                        sx: { width: '100%' },
                        label: 'Interval',
                        value: interval,
                        onChange: (event) => setInterval(event.target.value as string)
                    }}
                />
            ]
        },
        {
            title: 'Filters',
            filterComponents
        }
    ]
    return (<FilterLayout sections={sections} />);
}

export default FilterSections;
