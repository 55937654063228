import { GridColDef } from "@mui/x-data-grid"

export const GROUP_TABLE_TITLE = 'Tile events';

export const defaultDateColumn: GridColDef = {
    field: 'date',
    headerName: 'Date',
    type: 'date',
    flex: 1,
    filterable: false,
}

export const defaultGroupByColumn: GridColDef = {
    field: 'groupBy',
    headerName: 'Group By',
    type: 'string',
    flex: 3,
}

export const defaultCountColumn: GridColDef = {
    field: 'count',
    headerName: 'Tile Event Count',
    type: 'number',
    flex: 1,
}


