import * as React from 'react';
import BarChart from '@mui/icons-material/BarChart';
import ShowChart from '@mui/icons-material/ShowChart';
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import useStore from '../context/store';

export default function ChartToggleButtons() {

  const [
      chartType,
      setChartType,
  ] = useStore((state) => [
      state.chartType,
      state.setChartType,
  ]);

  const handleChartTypeChange = (
    event: React.MouseEvent<HTMLElement>,
    newChartType: string | null,
  ) => {
    setChartType(newChartType);
  };

  return (
    <ToggleButtonGroup
      value={chartType}
      exclusive
      onChange={handleChartTypeChange}
      aria-label="chart type"
    >
      <ToggleButton value="line" aria-label="line chart">
        <ShowChart />
      </ToggleButton>
      <ToggleButton value="bar" aria-label="bar chart">
        <BarChart />
      </ToggleButton>
    </ToggleButtonGroup>
  );
}
