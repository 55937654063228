import React from 'react';
import Grid from '@mui/material/Unstable_Grid2';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import { Typography } from '@mui/material';

import { DEFAULT_APP_NAME } from '../data/app';


interface BreakdownLayoutProps {
  title?: string;
  filterLayout: React.ReactNode;
  tableComponent: React.ReactNode;
  visualisationComponent: React.ReactNode;
}


const BreakdownLayout: React.FC<BreakdownLayoutProps> = ({
  title = DEFAULT_APP_NAME,
  filterLayout,
  tableComponent,
  visualisationComponent
}) => {
  return (
    <Grid container spacing={2}>
      <Grid xs={12}>
        <Typography variant={'h4'}>{title}</Typography>
      </Grid>
       {filterLayout}
      <Grid xs={12}>
        <Stack
          direction='column'
          spacing={{ xs: 1, sm: 2, md: 4 }}
        >
          <Box width={'100%'}>
            {visualisationComponent}
          </Box>
          <Box width={'100%'}>
            {tableComponent}
          </Box>
        </Stack>
      </Grid>
    </Grid>
  );
}

export default BreakdownLayout;
