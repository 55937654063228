import React from 'react';
import {Box, Typography} from "@mui/material";
import ChartToggleButtons from './ChartToggleButtons';
import Visualisation from "./Visualisation";
import {VISUALISATION_TITLE} from "../data/visualisation";


const VISUALISAION_HEIGHT = '25rem';


interface VisualisationWrapperProps {
    data: any[];
    visualisationTitle?: string;
}

const VisualisationWrapper: React.FC<VisualisationWrapperProps> = ({
                                                       data,
                                                       visualisationTitle = VISUALISATION_TITLE,
                                                   }) => {
    return (
        <Box width={'100%'} height={VISUALISAION_HEIGHT}>
            <Box display="flex" justifyContent="space-between" alignItems="center" flexWrap="wrap">
                <Typography variant='h5'>{visualisationTitle}</Typography>
                <ChartToggleButtons />
            </Box>
            <Visualisation
                data = {data}
            />
        </Box>
    )
};

export default VisualisationWrapper;

