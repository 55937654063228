export const oneMonthAgoAtMidnight = () => {
    const date = new Date();
    date.setMonth(date.getMonth() - 1);
    date.setHours(0, 0, 0, 0);
    return date;
}

export const todayAtMidnight = () => {
    const date = new Date();
    date.setHours(0, 0, 0, 0);
    return date;
}

export const generateComponentTitleFromDate = (start: Date | null, end: Date | null, interval: string, defaultTitle: string): string => {
    if (!start || !end) {
        return defaultTitle;
    }

    if (isNaN(start.getTime()) || isNaN(end.getTime())) {
        throw new Error("Invalid date format");
    }

    let diff: number;
    switch(interval) {
        case 'day':
            diff = (end.getTime() - start.getTime()) / (1000 * 60 * 60 * 24);
            break;
        case 'week':
            diff = (end.getTime() - start.getTime()) / (1000 * 60 * 60 * 24 * 7);
            break;
        case 'month':
            diff = (end.getTime() - start.getTime()) / (1000 * 60 * 60 * 24 * 30.44);
            break;
        case 'year':
            diff = (end.getTime() - start.getTime()) / (1000 * 60 * 60 * 24 * 365.25);
            break;
        default:
            throw new Error("Unsupported interval");
    }

    const roundedDiff = Math.round(diff);

    if (roundedDiff === 0) {
        return `${defaultTitle} over 1 ${interval}`;
    }

    return `${defaultTitle} over ${roundedDiff} ${interval}${roundedDiff !== 1 ? 's' : ''}`;
}
