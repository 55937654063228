import { GroupByElasticsearchResponse } from "../types/analyticsQuery";
import { OrganisationAPIResponse } from "../types/opsQuery";
const REACT_APP_TILE_EVENT_URL = process.env.REACT_APP_TILE_EVENT_URL;
const REACT_APP_OPS_API_USERS_ENDPOINT = process.env.REACT_APP_OPS_API_USERS_ENDPOINT;
const REACT_APP_OPS_API_ORGANISATIONS_ENDPOINT = process.env.REACT_APP_OPS_API_ORGANISATIONS_ENDPOINT;

const headers = {
    'Content-Type': 'application/json',
};

export interface DataFetchWithIdNames {
    userAnalyticsData: GroupByElasticsearchResponse
    idNames: OrganisationAPIResponse
}

export const fetchAnalyticsData = async (query: object, typeId: string): Promise<DataFetchWithIdNames> => {
    const userAnalyticsData = await fetchData(query, REACT_APP_TILE_EVENT_URL!);

    const buckets = userAnalyticsData.aggregations.events_over_time.buckets;
    const idsSet = new Set()

    for (const bucket of buckets) {
        for (const groupByBucket of bucket.group_by.buckets) {
            idsSet.add(groupByBucket.key)
        }
    }
    const idNames: OrganisationAPIResponse = await fetchIds(Array.from(idsSet) as string[], typeId);
    return {
        userAnalyticsData, 
        idNames
    }
}

export const fetchIds = async (query: string[], typeId: string) => {
    if(typeId === "token"){
        const userName = await fetchOpsUsersData(query);
        const userToNameMap: any = {};
        Object.keys(userName).forEach(  
        (key) => {
            userToNameMap[key] = {name: `${userName[key].first_name} ${userName[key].last_name}`}
        }
        )
        return userToNameMap;
    }
    if(typeId === "organisationId"){
        return fetchOpsOrganisationsData(query);
    }
}

export const fetchOpsUsersData = async (query: string[]) => {
    return fetchData(query, REACT_APP_OPS_API_USERS_ENDPOINT!);
}

export const fetchOpsOrganisationsData = async (query: string[]) => {
    return fetchData(query, REACT_APP_OPS_API_ORGANISATIONS_ENDPOINT!);
}

export const fetchData = async (query: object | string[], url: string) => {
    const response = await fetch(url, {
        method: 'POST',
        headers: headers,
        body: JSON.stringify(query)
    });

    if (!response.ok) {
        throw new Error('Network response was not ok');
    }

    const json = await response.json();
    return json;
}
