import { Page } from '../types/app';

export const DEFAULT_APP_NAME = 'Medusa App';

export const PAGES: Page[] = [
    { keyword: 'token', name: 'Users', path: 'users', columnName: 'User Name' },
    { keyword: 'organisationId', name: 'Organisation', path: 'organisation', columnName: 'Organisation' },
]

export const PRIMARY_COLOR = '#5e3ad8'; // Zonda Purple
export const SECONDARY_COLOR = '#b4d83a'; // Complementary colour