import React from 'react';
import { DataGrid, GridColDef, DataGridProps } from '@mui/x-data-grid';
import { Box, Typography } from '@mui/material';
import {GROUP_TABLE_TITLE} from "../data/table";


const TABLE_HEIGHT = '25rem';


interface TableWrapperProps {
  data: any[];
  tableTitle?: string;
  filtersText?: string;
  columns: GridColDef[];
  tableProps?: Partial<DataGridProps>;
}

const TableWrapper: React.FC<TableWrapperProps> = ({
  data,
  columns = [],
  tableTitle = GROUP_TABLE_TITLE,
  filtersText = '',
  tableProps = {}
}) => {
  return (
    <Box width={'100%'} height={TABLE_HEIGHT}>
      <Box display="flex" justifyContent="space-between" alignItems="center" flexWrap="wrap">
        <Typography variant='h5'>{tableTitle}</Typography>
        <Typography variant='body2' color="textSecondary">{filtersText}</Typography>
      </Box>
      <DataGrid
        {...tableProps}
        columns={columns}
        rows={data}
        pageSizeOptions={[5]}
        pagination={true}
        initialState={{
          pagination: { paginationModel: { pageSize: 5 } },
        }}
      />
    </Box>
  )
};

export default TableWrapper;
