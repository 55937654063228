import React from 'react';
import {
    Select as MaterialSelect,
    MenuItem,
    SelectProps as MaterialSelectProps,
    FormControl,
    InputLabel,
    IconButton
 } from '@mui/material';

 import { Refresh } from '@mui/icons-material';

interface MenuItemProps {
    name: string,
    value: string
}

interface SelectProps {
    items: MenuItemProps[],
    label: string,
    selectProps?: MaterialSelectProps,
    clearFunction?: () => void
}

const Select: React.FC<SelectProps> = ({
    items,
    label,
    selectProps,
    clearFunction
}) => {
    const labelId = `select-${label}`
    return (
        <FormControl fullWidth>
            <InputLabel id={labelId}>{label}</InputLabel>
            <MaterialSelect
                label={label}
                labelId={labelId}
                {...selectProps}
                fullWidth
                defaultValue={''}
                startAdornment={(clearFunction && (selectProps?.value ?? undefined))
                    && <IconButton onClick={clearFunction}><Refresh/></IconButton>}
            >
                {items.map(({name, value}, index) => <MenuItem key={index} value={value}>{name}</MenuItem> )}
            </MaterialSelect>
        </FormControl>
    );
}

export default Select;